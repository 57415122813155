<template>
  <div>
    <v-card-text>
      <v-form
        ref="updateFamilyForm"
        @submit.prevent="handleFormSubmit"
      >
        <div class="d-flex">
          <div>
            <v-text-field
              ref="familyNameRef"
              v-model="familyName"
              :readonly="!isEditFamilyName"
              dense
              :label="$t('FamilyName')"
              :placeholder="$t('FamilyName')"
              hide-details="auto"
              class="mb-6 mr-2"
              :rules="[validators.required]"
              validate-on-blur
              @keydown.esc="disableEditFamilyName"
              @click="enableEditFamilyName"
            >
            </v-text-field>
          </div>
          <div
            class="ml-auto flex-grow-1 d-flex justify-end"
          >
            <v-btn
              v-if="!isEditFamilyName"
              small
              color="primary"
              type="button"
              @click.prevent.stop="enableEditFamilyName"
            >
              {{ $t("Edit") }}
            </v-btn>
            <template v-else>
              <v-btn
                small
                color="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t("Save") }}
              </v-btn>
              <v-btn
                small
                @click="disableEditFamilyName"
              >
                {{ $t("Cancel") }}
              </v-btn>
            </template>
          </div>
        </div>
      </v-form>
    </v-card-text>

    <v-card-text>
      <v-row class="justify-space-between align-center mx-1">
        <span class="text-md font-weight-semibold text--primary">
          {{ $t("ListMembers") }}
        </span>
        <v-btn
          color="primary"
          outlined
          small
          @click="$router.push({ name: 'user-family-add-member' })"
        >
          <v-icon
            size="16"
            left
          >
            {{ icons.mdiAccountPlus }}
          </v-icon>
          {{ $t('AddMember') }}
        </v-btn>
      </v-row>
      <v-list
        v-if="familyMembers.length > 0"
        class="mt-4"
      >
        <template v-for="(item) in familyMembers">
          <v-list-item
            :key="item.id"
            class="px-0"
            @click="showMemberDetail(item)"
          >
            <v-list-item-avatar>
              <v-img :src="item.face_image_url"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ item.full_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text--primary">
                  {{ $t(typeMember(item.type_member)) }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <div class="text-center">
                <span
                  v-if="item.joined_status < 1"
                  class="text-sm"
                  style="fontStyle: italic"
                >
                  {{ $t("WaitingToAccept") }}
                </span>

                <v-btn
                  v-else
                  color=""
                  icon
                >
                  <v-icon size="20">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>

          <v-divider
            :key="`divider-${item.id_user}`"
            :inset="item.inset"
          ></v-divider>
        </template>
      </v-list>
    </v-card-text>

    <v-card-text class="d-flex justify-center">
      <v-btn
        small
        outlined
        color="error"
        @click="isShowDialogLeave = true"
      >
        {{ $t('LeaveFamily') }}
      </v-btn>
    </v-card-text>
    <v-dialog v-model="showDetail">
      <v-card>
        <v-card-title>{{ $t('Details') }}</v-card-title>
        <v-card-text>
          <h3 class="font-weight-semibold mb-2">
            <span class="primary--text">{{ selectedMember.full_name }}</span>
            <v-chip
              v-if="selectedMember.joined_status"
              small
              color="primary"
              outlined
              class="v-chip-light-bg primary--text font-weight-medium text-capitalize ml-2"
            >
              {{ $t(typeMember(selectedMember.type_member)) }}
            </v-chip>
          </h3>
          <v-divider></v-divider>

          <v-list class="my-0 py-0">
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t('Gender') }}:</span>
              <span class="text--secondary text-capitalize">{{ $t(getGender(selectedMember.gender)) }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('PhoneNumber') }}:</span>
              <span class="text--secondary">{{ selectedMember.phone }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t('YearOfBirth') }}:</span>
              <span class="text--secondary">{{ selectedMember.year_of_birth }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2 d-block mt-2"
            >
              <div class="d-flex">
                <div
                  class="pr-2"
                  style="flex: 0 1 50%; width: 50%"
                >
                  <v-img
                    :src="selectedMember.face_image_url"
                    width="100%"
                  ></v-img>
                </div>
                <div
                  class="pl-2"
                  style="flex: 0 1 50%; width: 50%"
                >
                  <v-img
                    :src="selectedMember.face_mask_image_url"
                    class="mx-2"
                    width="100%"
                  ></v-img>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="mt-2">
          <v-btn
            v-if="currentUser.id_user !== selectedMember.id_user && !selectedMember.is_owner"
            color="error"
            text
            :loading="isRemovingMember"
            @click="handleRemoveMember(selectedMember)"
          >
            {{ $t('RemoveMember') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="showDetail = false"
          >
            {{ $t('Back') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-if="isShowDialogLeave"
      :title="$t('AreYouSure')"
      :content="$t('AreYouSureToLeave')"
      @cancel="isShowDialogLeave = false"
      @ok="leaveFamily"
    ></ConfirmDialog>
  </div>
</template>

<script>
import { mdiAccountPlus, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import FamilyService from '@/services/FamilyService'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { typeMember } from '@/utils/familyHelper'
import store from '@/store'

import { getGender } from '@/utils/accountHelper'

export default {
  emits: ['leaveFamilySuccess'],
  components: {
    ConfirmDialog,
  },
  props: {
    familyMembers: {
      type: [Array, Object],
      default: () => [],
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const currentUser = store.getters['auth/currentUser']
    const updateFamilyForm = ref(null)
    const familyNameRef = ref(null)
    const familyName = ref(currentUser.family_name)
    const isEditFamilyName = ref(false)
    const showDetail = ref(false)
    const selectedMember = ref({})
    const isRemovingMember = ref(false)
    const isShowDialogLeave = ref(false)

    const enableEditFamilyName = () => {
      isEditFamilyName.value = true
      familyNameRef.value.focus()
    }

    const disableEditFamilyName = () => {
      familyName.value = currentUser.family_name
      isEditFamilyName.value = false
      familyNameRef.value.blur()
    }

    const handleFormSubmit = async () => {
      const isFormValid = updateFamilyForm.value.validate()

      if (!isFormValid) return

      await FamilyService.update({
        family_name: familyName.value,
      })

      store.commit('auth/setFamilyName', familyName.value)

      isEditFamilyName.value = false
      familyNameRef.value.blur()
    }

    const showMemberDetail = member => {
      showDetail.value = true
      selectedMember.value = member
    }

    const leaveFamily = async () => {
      await FamilyService.leave()
      store.commit('auth/setFamilyId', 0)
      emit('leaveFamilySuccess')
    }

    const handleRemoveMember = async member => {
      try {
        isRemovingMember.value = true
        await FamilyService.removeMember(member.id_user)
        store.commit('snackbar/show', {
          type: 'success',
          message: 'Remove member successfully',
        })
        emit('removeMemberSuccess')
        showDetail.value = false
      } catch {
      } finally {
        isRemovingMember.value = false
      }
    }

    return {
      familyNameRef,
      familyName,
      isEditFamilyName,
      isRemovingMember,

      updateFamilyForm,
      enableEditFamilyName,
      disableEditFamilyName,
      handleFormSubmit,
      leaveFamily,

      typeMember,

      showDetail,
      selectedMember,
      showMemberDetail,
      handleRemoveMember,

      currentUser,
      isShowDialogLeave,

      validators: {
        required,
      },

      icons: {
        mdiAccountPlus,
        mdiEyeOutline,
      },

      getGender,
    }
  },
}
</script>
