<template>
  <v-card-text
    class="d-flex justify-center flex-column"
  >
    <v-row class="justify-center">
      {{ $t('NoFamilyMsg') }}
    </v-row>

    <v-row class="mt-8">
      <v-btn
        block
        color="primary"
        @click="showCreateFamilyDialog"
      >
        {{ $t('CreateNewFamily') }}
      </v-btn>
    </v-row>

    <user-family-create-dialog
      :key="dialogKey"
      :is-create-family-dialog-open.sync="isCreateFamilyDialogOpen"
      @handleCreateFamilySuccess="handleCreateFamilySuccess"
    ></user-family-create-dialog>
  </v-card-text>
</template>

<script>
import { ref } from '@vue/composition-api'
import UserFamilyCreateDialog from './UserFamilyCreateDialog.vue'

export default {
  components: {
    UserFamilyCreateDialog,
  },
  emits: ['createFamilySuccess'],
  setup(_, { emit }) {
    const dialogKey = ref(0)
    const isCreateFamilyDialogOpen = ref(false)

    const showCreateFamilyDialog = () => {
      isCreateFamilyDialogOpen.value = !isCreateFamilyDialogOpen.value
      dialogKey.value += 1
    }

    const handleCreateFamilySuccess = () => {
      isCreateFamilyDialogOpen.value = false
      emit('createFamilySuccess')
    }

    return {
      dialogKey,
      isCreateFamilyDialogOpen,

      showCreateFamilyDialog,
      handleCreateFamilySuccess,
    }
  },
}
</script>
