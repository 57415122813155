<template>
  <v-card>
    <v-card-title class="justify-center flex-column">
      {{ $t('Family') }}
    </v-card-title>

    <user-family-information
      v-if="hasFamily"
      :family-members="familyData"
      :name="familyName"
      @removeMemberSuccess="handleRemoveMemberSuccess"
      @leaveFamilySuccess="handleLeaveFamilySuccess"
    />

    <user-family-invitation
      v-else-if="!hasFamily && !!hasInvitations"
      :has-invitations.sync="hasInvitations"
      :invitation-data="invitationData"
      @joinFamilySuccess="handleJoinFamilySuccess"
      @denyFamilySuccess="handleDenyFamilySuccess"
    />

    <user-family-create
      v-else
      @cr="handleCreateFamilySuccess"
      @createFamilySuccess="handleCreateFamilySuccess"
    />
  </v-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import UserFamilyInformation from './components/UserFamilyInformation.vue'
import UserFamilyCreate from './components/UserFamilyCreate.vue'
import UserFamilyInvitation from './components/UserFamilyInvitation.vue'

import FamilyService from '@/services/FamilyService'
import store from '@/store'

// import store from '@/store'

export default {
  components: {
    UserFamilyInformation,
    UserFamilyCreate,
    UserFamilyInvitation,
  },
  setup() {
    const hasFamily = ref(false)
    const hasInvitations = ref(false)
    const familyName = ref(false)
    const familyData = ref({})
    const invitationData = ref({})

    const getData = async () => {
      FamilyService.get().then(res => {
        familyData.value = res.data.data?.members
        hasFamily.value = !!res.data.data?.name
        familyName.value = res.data.data?.name
        store.commit('auth/setFamilyName', familyName.value)

        // If user doesn't have family, look for invitations
        if (!hasFamily.value) {
          FamilyService.listInvites().then(resp => {
            invitationData.value = resp.data.data
            hasInvitations.value = resp.data.data.length > 0
          })
        }
      })
    }
    onMounted(async () => {
      await getData()
    })

    const handleCreateFamilySuccess = () => {
      getData()
    }

    const handleLeaveFamilySuccess = () => {
      getData()
    }

    const handleJoinFamilySuccess = () => {
      getData()
    }

    const handleDenyFamilySuccess = () => {
      getData()
    }

    const handleRemoveMemberSuccess = () => {
      getData()
    }

    return {
      familyData,
      hasFamily,
      familyName,
      hasInvitations,
      invitationData,
      handleRemoveMemberSuccess,
      handleCreateFamilySuccess,
      handleLeaveFamilySuccess,
      handleJoinFamilySuccess,
      handleDenyFamilySuccess,
    }
  },
}
</script>
