<template>
  <v-dialog
    v-model="isFamilyInvitationConfirmOpen"
    max-width="650px"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t('FamilyInvitationTitle') }}
      </v-card-title>
      <v-card-text>
        <i18n path="FamilyInvitationSubtitle">
          <template #name>
            <strong>{{ invitation.name }}</strong>
          </template>
          <template #role>
            <strong>{{ $t(invitation.role) }}</strong>
          </template>
        </i18n>

        <v-row class="mt-1">
          <v-col>
            <v-btn
              block
              color="primary"
              @click="handleJoinFamily"
            >
              {{ $t('Yes') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              outlined
              block
              @click="$emit('update:is-family-invitation-confirm-open',false)"
            >
              {{ $t('No') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FamilyService from '@/services/FamilyService'

export default {
  props: {
    isFamilyInvitationConfirmOpen: {
      type: Boolean,
      required: true,
    },
    invitation: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['handleJoinFamilySuccess'],
  setup(props, { emit }) {
    const handleJoinFamily = async () => {
      await FamilyService.memberConfirm({
        id_invite: props.invitation.id_invite,
      })
      emit('handleJoinFamilySuccess')
    }

    return {
      handleJoinFamily,
    }
  },
}
</script>
