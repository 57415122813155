<template>
  <v-dialog
    v-model="isCreateFamilyDialogOpen"
    max-width="650px"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t('CreateNewFamily') }}
      </v-card-title>

      <v-card-text>
        <v-form
          ref="createFamilyForm"
          @submit.prevent="handleSubmitForm"
        >
          <v-text-field
            v-model="familyName"
            dense
            outlined
            :label="$t('FamilyName')"
            :placeholder="$t('FamilyName')"
            hide-details="auto"
            class="mb-6"
            :rules="[validators.required]"
            validate-on-blur
          ></v-text-field>

          <v-row>
            <v-col>
              <v-btn
                block
                color="primary"
                type="submit"
              >
                {{ $t('Submit') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                outlined
                block
                @click="$emit('update:is-create-family-dialog-open',false)"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import FamilyService from '@/services/FamilyService'

export default {
  props: {
    isCreateFamilyDialogOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['handleCreateFamilySuccess'],
  setup(_, { emit }) {
    // Template ref
    const createFamilyForm = ref(null)

    const familyName = ref('')

    const handleSubmitForm = async () => {
      const isFormValid = createFamilyForm.value.validate()

      if (!isFormValid) return
      await FamilyService.create({
        family_name: familyName.value,
      })
      emit('handleCreateFamilySuccess')
    }

    return {
      familyName,
      handleSubmitForm,
      validators: {
        required,
      },

      // Template Refs
      createFamilyForm,
    }
  },
}
</script>
