<template>
  <v-card-text
    class="d-flex flex-column"
  >
    <v-row class="justify-center mx-1 mb-1 mb-4">
      {{ $t('FamilyInvitationsSubtitle', {count: invitationData.length}) }}
    </v-row>
    <v-card
      v-for="(item, index) in invitationData"
      :key="index"
      class="mb-2"
    >
      <v-card-text class="pa-2 pb-4">
        <i18n path="FamilyInvitationsMsg">
          <template #name>
            <strong>{{ item.full_name }}</strong>
          </template>
          <template #phoneNumber>
            <strong>{{ item.phone }}</strong>
          </template>
          <template #role>
            <strong>{{ $t(typeMember(item.type_member)) }}</strong>
          </template>
        </i18n>
      </v-card-text>
      <v-card-actions class="px-2 pb-2">
        <v-spacer />
        <v-btn
          color="primary"
          small
          @click="showFamilyInvitationConfirm(item)"
        >
          {{ $t('Join') }}
        </v-btn>
        <v-btn
          class="ml-4"
          outlined
          small
          @click="denyInvitation(item)"
        >
          {{ $t('Deny') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <user-family-invitation-confirm
      v-if="!!selectedInvitation"
      :key="dialogKey"
      :invitation="selectedInvitation"
      :is-family-invitation-confirm-open.sync="isFamilyInvitationConfirmOpen"
      @handleJoinFamilySuccess="handleJoinFamilySuccess"
    ></user-family-invitation-confirm>
  </v-card-text>
</template>

<script>
import { ref } from '@vue/composition-api'
import UserFamilyInvitationConfirm from './UserFamilyInvitationConfirm.vue'
import FamilyService from '@/services/FamilyService'
import { typeMember } from '@/utils/familyHelper'

export default {
  components: {
    UserFamilyInvitationConfirm,
  },
  props: {
    hasInvitations: {
      type: Boolean,
      require: true,
    },
    invitationData: {
      type: Array,
      require: true,
      default: () => {},
    },
  },
  emits: ['joinFamilySuccess', 'denyFamilySuccess'],
  setup(props, { emit }) {
    const dialogKey = ref(0)
    const isFamilyInvitationConfirmOpen = ref(false)
    const selectedInvitation = ref(null)

    const showFamilyInvitationConfirm = invitation => {
      selectedInvitation.value = invitation
      isFamilyInvitationConfirmOpen.value = !isFamilyInvitationConfirmOpen.value
      dialogKey.value += 1
    }

    const denyInvitation = async invitation => {
      await FamilyService.memberDeny({
        id_invite: invitation.id_invite,
      })
      emit('denyFamilySuccess')
    }

    const handleJoinFamilySuccess = () => {
      isFamilyInvitationConfirmOpen.value = false
      emit('joinFamilySuccess')
    }

    return {
      selectedInvitation,
      isFamilyInvitationConfirmOpen,
      dialogKey,

      showFamilyInvitationConfirm,
      handleJoinFamilySuccess,
      denyInvitation,
      typeMember,
    }
  },
}
</script>
